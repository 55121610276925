import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = ["id", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.assetExists())
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "inline-block cursor-pointer",
        title: _ctx.trait + ': ' + _ctx.traitName,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (
      _ctx.setCurrentTrait({
        attributes: {
          ..._ctx.getChosenCat.attributes,
          [_ctx.trait]: _ctx.traitName,
        },
      })
    ))
      }, [
        _createElementVNode("canvas", {
          id: _ctx.traitName.replace(' ', '_'),
          width: 24 * _ctx.scale,
          height: 24 * _ctx.scale,
          class: "mx-auto cursor-pointer"
        }, null, 8, _hoisted_2)
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}