import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-033a1eda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "text-sm border-2 border-white p-5" }
const _hoisted_3 = {
  key: 0,
  class: "m-2"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "border-2 border-white cursor-pointer" }
const _hoisted_7 = ["onMousedown", "onMouseenter", "onMouseleave"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cat_info.attributes, (m, i) => {
        return (_openBlock(), _createElementBlock("div", { key: m }, [
          (!Number.isFinite(m) && i != 'background' && i != 'type')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createTextVNode(_toDisplayString(i.charAt(0) + i.slice(1) + ": ") + " ", 1),
                (
              m != 'None' &&
              !Number.isFinite(m) &&
              i != 'background' &&
              i != 'type'
            )
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass([
              { 'bg-green-500': _ctx.toggleTraits[i] },
              { 'bg-red-500': !_ctx.toggleTraits[i] },
              'text-white rounded-md p-1 hover:bg-black cursor-pointer',
            ]),
                      onClick: ($event: any) => {
              _ctx.toggleTraits[i] = !_ctx.toggleTraits[i];
              _ctx.rerun();
            }
                    }, _toDisplayString(_ctx.toggleTraits[i] ? "on" : "off"), 11, _hoisted_4))
                  : (_openBlock(), _createElementBlock("span", _hoisted_5, "none"))
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dimensions, (d) => {
        return (_openBlock(), _createElementBlock("div", {
          key: d,
          class: "grid grid-cols-24"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dimensions, (i) => {
            return (_openBlock(), _createElementBlock("div", {
              onMousedown: ($event: any) => (_ctx.popPixel(_ctx.getMyIndex(d, i, _ctx.dimensions))),
              key: i,
              onMouseenter: ($event: any) => (
            [
              (_ctx.hover[_ctx.getMyIndex(d, i, _ctx.dimensions)] = true),
              _ctx.isGlide($event, d, i, _ctx.dimensions),
            ]
          ),
              onMouseleave: ($event: any) => (_ctx.hover[_ctx.getMyIndex(d, i, _ctx.dimensions)] = false),
              style: _normalizeStyle({
            backgroundColor: _ctx.bgColor(d, i, _ctx.dimensions),
            'hover:backgroundColor': 'red',
            opacity: _ctx.opacity[_ctx.getMyIndex(d, i, _ctx.dimensions)],
            height: _ctx.h,
            width: _ctx.w,
          }),
              class: _normalizeClass({ pixel: _ctx.hover[_ctx.getMyIndex(d, i, _ctx.dimensions)] })
            }, null, 46, _hoisted_7))
          }), 128))
        ]))
      }), 128))
    ])
  ]))
}