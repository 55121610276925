import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-df920760"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 1,
  class: "mx-auto text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_slider = _resolveComponent("vue-slider")!
  const _component_v_swatches = _resolveComponent("v-swatches")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "text-center bg-white text-black hover:bg-black hover:text-white w-2/3 mt-10 mx-auto rounded-full cursor-pointer p-2",
      onClick: _cache[0] || (_cache[0] = ($event: any) => {
        _ctx.toggleFilter();
        _ctx.isFilterOn
          ? _ctx.currentFilter == 'CUSTOM'
            ? _ctx.setBackground($setup.background)
            : _ctx.setBackground(
                $options.filters.filter((item) => {
                  return item.name == _ctx.currentFilter;
                })[0].background
              )
          : _ctx.setBackground(_ctx.getDefaultBackground);
      })
    }, _toDisplayString(_ctx.isFilterOn ? "remove" : "activate") + " filter ", 1),
    (_ctx.isFilterOn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_vue_slider, {
            min: 0,
            max: 765,
            modelValue: $setup.thresh,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.thresh) = $event)),
            width: 200,
            class: "mx-auto mt-2"
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", {
            class: "mt-2 text-center mx-auto bg-white hover:bg-black hover:text-white text-black w-2/3 rounded-full cursor-pointer",
            onClick: _cache[2] || (_cache[2] = ($event: any) => ($data.menu = !$data.menu))
          }, " filter: " + _toDisplayString(_ctx.currentFilter) + " ▼ ", 1),
          ($data.menu)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filters, (f) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "mt-1 text-center mx-auto bg-white hover:bg-black hover:text-white text-black w-1/3 cursor-pointer",
                    key: f.name,
                    onClick: ($event: any) => {
            _ctx.changeFilter(f.name);
            $data.menu = !$data.menu;
            _ctx.setBackground(
              _ctx.currentFilter == 'CUSTOM'
                ? $setup.background
                : f.background
            );
          }
                  }, _toDisplayString(f.name), 9, _hoisted_3))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.currentFilter == 'CUSTOM')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_v_swatches, {
                  modelValue: $setup.foreground,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.foreground) = $event)),
                  "show-fallback": "",
                  "fallback-input-type": "color",
                  "popover-x": "left",
                  class: "border-solid border-2 border-white mt-5",
                  style: _normalizeStyle('backgroundColor: ' + $setup.foreground)
                }, null, 8, ["modelValue", "style"]),
                _createVNode(_component_v_swatches, {
                  modelValue: $setup.background,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.background) = $event)),
                  "show-fallback": "",
                  "fallback-input-type": "color",
                  "popover-x": "left",
                  class: "border-solid border-2 border-white mt-5",
                  style: _normalizeStyle('backgroundColor: ' + $setup.background)
                }, null, 8, ["modelValue", "style"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}