
import "vue-swatches/dist/vue-swatches.css";
import VSwatches from "vue3-swatches";
import "vue-slider-component/theme/antd.css";
import VueSlider from "vue-slider-component";
import { mapActions, mapGetters } from "vuex";
import filters from "@/assets/filters.json";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "Filters",
  components: {
    VueSlider,
    VSwatches,
  },
  setup() {
    const store = useStore();
    const thresh = computed({
      get: () => {
        return store.getters.whatThreshold;
      },
      set: (value) => {
        store.dispatch("changeFilterThreshold", value);
      },
    });

    const foreground = computed({
      get: () => {
        return store.getters.getCustomBackgroundPixel;
      },
      set: (value) => {
        store.dispatch("setCustomBackgroundPixel", value);
      },
    });

    const background = computed({
      get: () => {
        return store.getters.getBackground;
      },
      set: (value) => {
        store.dispatch("setBackground", value);
      },
    });

    return {
      thresh,
      foreground,
      background,
    };
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    filters() {
      return filters;
    },
    ...mapGetters({
      getDefaultBackground: "getDefaultBackground",
      getCustomBackgroundPixel: "getCustomBackgroundPixel",
      getBackground: "getBackground",
      isFilterOn: "isFilterOn",
      whatThreshold: "whatThreshold",
      currentFilter: "currentFilter",
    }),
  },
  methods: {
    ...mapActions({
      setCustomBackgroundPixel: "setCustomBackgroundPixel",
      setBackground: "setBackground",
      toggleFilter: "filterSwitch",
      changeFilter: "changeFilter",
      changeFilterThreshold: "changeFilterThreshold",
    }),
  },
};
