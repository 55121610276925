
import { Options, Vue } from "vue-class-component";
import CopyCatInteractive from "./CopyCatInteractive.vue";
import { mapGetters, mapActions } from "vuex";
import Filters from "./Filters.vue";
import AssetsManager from "./AssetsManager.vue";
@Options({
  components: {
    CopyCatInteractive,
    Filters,
    AssetsManager,
  },
  computed: {
    passCat() {
      return this.$store.getters.getChosenCat;
    },
    ...mapGetters({
      playing: "isPlaying",
      score: "currentScore",
      getDefaultBackground: "getDefaultBackground",
      isFilterOn: "isFilterOn",
      atLeastTwo: "atLeastTwo",
    }),
  },
  methods: {
    resetGame() {
      this.setPlayStatus(false);
      this.setKeyword("");
      this.changeCat("");
      this.resetScore();
      if (this.isFilterOn) {
        this.toggleFilter();
        this.setBackground(this.getDefaultBackground);
      }
    },
    ...mapActions({
      setKeyword:"setKeyword",
      setPlayStatus: "setPlayStatus",
      changeCat: "selectCat",
      resetScore: "resetScore",
      toggleFilter: "filterSwitch",
      setBackground: "setBackground",
    }),
  },
  props: {
    img: String,
  },
})
export default class Play extends Vue {}
