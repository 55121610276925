import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-6" }
const _hoisted_2 = { class: "text-center text-2xl" }
const _hoisted_3 = { class: "grid grid-cols-12 mt-5" }
const _hoisted_4 = { class: "col-span-12 md:col-span-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_copy_cat_interactive = _resolveComponent("copy-cat-interactive")!
  const _component_assets_manager = _resolveComponent("assets-manager")!
  const _component_filters = _resolveComponent("filters")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, "Score: " + _toDisplayString(_ctx.score), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_copy_cat_interactive, {
        class: "mx-auto mt-2 col-span-12 md:col-span-7",
        cat_info: _ctx.passCat,
        h: "15px",
        w: "15px"
      }, null, 8, ["cat_info"]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.atLeastTwo)
          ? (_openBlock(), _createBlock(_component_assets_manager, { key: 0 }))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "rounded-full text-center text-sm bg-green-400 text-black hover:bg-green-800 hover:text-white cursor-pointer mt-10 mx-auto p-2 w-2/3",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resetGame()))
        }, " choose a different cat "),
        _createVNode(_component_filters)
      ])
    ])
  ]))
}